<template>
  <div class="mb-2">
    <div
      :class="[section.animation_enabled ? getAnimation(section.animation) : '',{'ql_editor_height': getRouteName(this.self) !== 'content_text_block'}]"
      class="ql-editor" v-html="section.text"></div>
  </div>

</template>
<script>


export default ({
  methods: {
    getRouteName (self = this) {
      console.log(self.$route)
      return self.$route.name ? self.$route.name : ''
    }
  },
  props: ['section', 'self']
})
</script>
<style lang="scss">
.ql_editor_height {
  min-height: 0px !important;
}

</style>
